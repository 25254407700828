// import PropTypes from 'prop-types';
import React from 'react';
import { basePageWrap } from '../BasePage';

import Header from '../../components/Header';
import FadeIn from '../../components/FadeIn/FadeIn';
import Footer from '../../components/Footer';
import StreamField from '../../components/StreamField';

const LandingPage = ({ navigation, banner, hero, blocks, footer }) => {
    return (
        <>
            <Header {...navigation} banner={banner} modifiers={['black']} />
            <main>
                <StreamField blocks={hero} />
                <StreamField blocks={blocks} />
            </main>
            <Footer {...footer} />
        </>
    );
};

export default basePageWrap(LandingPage);
